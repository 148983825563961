.skeletonPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.customSkeleton {
  --width: 100%;
  --height: 100%;
  --border-radius: 0px;
}
