.container {
  padding: 12px 16px 12px 12px;

  :global {
    .adm-button:active::before {
      opacity: 0;
    }
  }
}

.button {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;

  svg {
    width: 21px;
    height: 21px;
  }

  &::before {
    content: '';
    width: 66px;
    height: 62px;
    position: absolute;
    top: -12px;
    left: -10px;
  }
}
