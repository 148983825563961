.container {
  margin-bottom: 32px;
  padding: 0 8px;
  position: relative;
}

.categoryTitle {
  margin: 0 0 16px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollAnchor {
  position: absolute;
  top: -76px;
  opacity: 0;
  pointer-events: none;
}

.chunksContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.virtualizedItem {
  height: 100%;
  border-radius: 8px;
  background-color: #f3f3f3;
  flex: 1;
}
