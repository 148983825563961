.item {
  width: 100%;
  min-width: 134px;
  min-height: 126px;
  padding: 16px 16px 14px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  justify-content: space-between;
}

.title {
  width: 100%;
  max-height: 22px;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weight {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #a5a5a5;
}

.bottomBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  display: block;
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}


.arrowIcon {
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;

    color: #333333;
  }
}
