.container {
  padding: 16px 16px 13px;
  position: relative;

  :global {
    .adm-divider-horizontal {
      margin: 0 -16px;
    }

    .adm-skeleton {
      &.adm-skeleton-title {
        margin-top: 0;
        margin-bottom: 7px;
      }
    }
  }
}

.headerContainer {
  position: relative;
}

.cartButton {
  position: absolute;
  top: -16px;
  right: -16px;
}

.title {
  margin: 0 0 15px;
  position: relative;
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.loadingTitle {
  margin: 0 auto 15px auto !important;
  min-width: 192px;
  height: 32px;
  border-radius: 8px;
}

.promoWrapper {
  margin-top: 24px;
  margin-bottom: 8px;
}

.searchTip {
  margin: 24px 0 0;
  font-size: 14px;
  line-height: 22px;
}

.searchResultsTitle {
  margin: 25px 0 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
