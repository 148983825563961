$i: 4;

@while $i <= 45 {
  .m-#{$i} {
    margin: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }

  .p-#{$i} {
    padding: #{$i}px;
  }

  .pt-#{$i} {
    padding-top: #{$i}px;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .pl-#{$i} {
    padding-left: #{$i}px;
  }

  .pr-#{$i} {
    padding-right: #{$i}px;
  }

  $i: $i + (4 * 1);
}

.margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

.link-no-styles {
  text-decoration: none;
}
