@import '/src/utils/scss/extensions.scss';

.popup {
  padding: 32px 24px 24px;
  border-radius: 16px;

  :global {
    .adm-action-sheet-extra {
      max-width: 400px;
      margin: 0 auto 24px;
      padding: 0;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #333333;
      border: none;
    }

    .adm-action-sheet-button-list {
      border: none;
    }

    .adm-action-sheet-button-item-wrapper {
      border: none;
    }

    .adm-action-sheet-button-item {
      @extend %submitButtonShared;
      width: auto;
    }

    .adm-action-sheet-button-item-name {
      font-size: 18px;
      color: #fff;
    }
  }
}

.popupContainer {
  @extend %popupContainerShared;
}
