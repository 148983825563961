@import './variables.scss';

%textButtonShared {
  height: fit-content;
  padding: 0;
  align-self: flex-end;
  background: $mainGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 24px;
  transition: all 0.2s ease-out;

  &:hover {
    background: $mainGradient;
    opacity: 0.8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

%submitButtonShared {
  width: 100%;
  padding: 12px;
  background: $mainGradient;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border: none;
  border-radius: 4px;
  line-height: 24px;
  color: #fff;
  transition: all 0.2s ease-out;

  &:hover {
    background: $mainGradient;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border: none;
    opacity: 0.8;
  }

  &:disabled {
    pointer-events: none;
    background: #e2e3e6;
    border: none;
    box-shadow: none;
  }
}

%popupContainerShared {
  :global {
    .adm-popup-body {
      max-width: 800px;

      @media screen and (min-width: $maxAppWidth) {
        left: calc(calc(100% - 800px) / 2);
      }
    }
  }
}
