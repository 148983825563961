@import 'utils/scss/extensions.scss';
@import 'utils/scss/variables';

.container {
  padding: 0 24px 24px;

  :global {
    .adm-error-block-full-page {
      padding-top: 40px;
    }
  }
}

.emptyCartError {
  :global {
    .adm-error-block-image {
      height: fit-content;

      svg {
        padding-left: 18px;
      }
    }

    .adm-error-block-description-title {
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
    }

    .adm-error-block-description-subtitle {
      margin: 4px 0 154px;
      text-align: left;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
    }

    @media screen and (min-width: $desktopBreakpoint) {
      .adm-error-block-description-title,
      .adm-error-block-description-subtitle {
        text-align: center;
      }
    }
  }
}

.button {
  @extend %submitButtonShared;
  max-width: 402px;
  font-size: 18px;
  line-height: 25px;
}
