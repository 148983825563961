@import '/src/utils/scss/variables.scss';

.option {
  &Name {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  &Price {
    font-size: 14px;
    line-height: 22px;
    color: #a1a1a1;
  }
}

.optionList {
  margin: 0;
  padding: 0;
  list-style: none;

  &Title {
    margin: 0 0 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}

.optionsContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;

  :global {
    .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
      background: $mainGradient;
      border: none;
    }
  }
}

.checkboxItem {
  --icon-size: 16px;

  :global {
    .adm-checkbox-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .adm-checkbox-icon {
      border-radius: 2px;
    }
  }
}
