@import 'utils/scss/extensions.scss';

.searchContainer {
  margin: 25px 0 24px;
}

.searchBar {
  :global {
    .adm-search-bar-input-box {
      background: #f5f5f5;
    }

    .adm-input-element {
      font-size: 16px;
      line-height: 140%;
    }

    .adm-input-clear {
      display: none;
    }

    .adm-button {
      @extend %textButtonShared;
      font-size: 16px;
      line-height: 140%;
    }
  }
}
