.cartContainer {
  padding: 12px 16px 12px 12px;
  position: relative;
  width: fit-content;
}

.cartButton {
  --color: #ff4d4f;
  --top: 10px;
  --right: 10px;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  color: #fff;
}

.cartIcon {
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  font-size: 24px;
  color: rgba(160, 160, 160, 0.85);
}
