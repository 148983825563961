.link {
  text-decoration: none;
  border-radius: 8px;
}

.container {
  min-height: 86px;

  &WithDescription {
    min-height: 130px;
  }
}

.item {
  position: relative;
  padding-bottom: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  box-shadow: 0px -1px 0px #EEEEEE inset;

  &Disabled {
    box-shadow: none;
  }
}

.disabledOverlay {
  position: absolute;
  background-color: #EDEDED;
  top: 0;
  left: -24px;
  right: -24px;
  bottom: 0;
  z-index: -1;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin: 8px 0 0 0;
  max-height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.description {
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a1a1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.subInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.weight {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #a5a5a5;
}

.price {
  display: block;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  line-height: 22px;
}

.disabled {
  pointer-events: none;

  .title {
    color: #a5a5a5;
  }
}

.arrowIcon {
  width: 16px;
  height: 16px;
  margin-top: 11px;

  svg {
    width: 100%;
    height: 100%;

    color: #333333;
  }

  &Disabled svg {
    color: #A1A1A1;
  }
}
