@import '/src/utils/scss/variables.scss';

.container {
  :global {
    .adm-button:active::before {
      opacity: 0;
    }
  }
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $mainGradient;
  border-radius: 50%;

  svg {
    color: #fff;
    width: 21px;
    height: 21px;
  }

  &::before {
    content: '';
    width: 60px;
    height: 62px;
    position: absolute;
    top: -12px;
    left: -10px;
  }
}
