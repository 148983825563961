@import '/src/utils/scss/variables.scss';

.header {
  min-height: 64px;
  position: relative;
}

.navBarContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  margin: 0 0 8px;
  padding: 0 24px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.orderList {
  margin: 0 0 16px;
  padding: 0 24px;
  list-style-type: none;
}

.orderItem {
  &:last-of-type {
    div:first-child {
      border-bottom: 0;
    }
  }
}

.footer {
  padding: 12px 24px 24px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  border-top: 1px solid #f2f2ef;
}

.cost {
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.duration {
  margin: 0 0 8px;
  display: block;
  font-size: 14px;
  line-height: 22px;
}

.text {
  max-width: 320px;
  margin: 0;
  padding: 8px 0;
  position: relative;
  text-align: center;
  background: $mainGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 17px;
  line-height: 140%;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1px;
    background: $mainGradient;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
