.dishPageContainer {
  margin-bottom: -24px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 23px);

  :global {
    .adm-divider {
      margin-bottom: 8px;
    }
  }
}

.navBarContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.cartButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.dishInfoContainer {
  margin: 8px 0 16px;
  padding: 0 24px;

  div:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.dishTitle {
  margin: 0 0 4px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.dishHeader {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.dishIngredients {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: #a2a2a2;
}

.dishWeight {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #9d9d9d;
}

.dishPrice {
  margin-right: 8px;
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.dishDescription {
  margin: 0 0 24px;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-line;
}

.headerContainer {
  width: 100%;
  height: 60px;
  position: relative;
}

.dishImageContainer {
  width: 100%;
  padding-top: 61%;
  position: relative;
}

.previewPromo {
  padding: 0 24px;
  margin-top: 8px;

  &Text {
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    word-wrap: break-word;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -ms-line-clamp: 2;
    -webkit-line-clamp: 2;
  }

  &Title {
    width: fit-content;
    padding: 5px 8px;
    z-index: 1;
    display: flex;
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(270deg, #faff1d 0%, #ffa800 100%);
    opacity: 100%;
    border-radius: 8px;
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;

    &Icon {
      margin: 3px 4px 0 0;
    }
  }

  &Description {
    max-height: 104px;
    padding: 20px 16px 8px;
    margin: -16px 0 0;
    box-sizing: border-box;
    background: linear-gradient(270deg, rgba(250, 255, 29, 0.2) 0%, rgba(255, 168, 0, 0.2) 100%);
    border-radius: 8px;
    font-size: 12px;
    line-height: 20px;
    -ms-line-clamp: 4;
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
}

.dishSectionTitle {
  padding: 0 24px;
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.promo {
  padding: 0 16px;
}

.disabled {
  .image {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  .title {
    color: #939393;
  }

  .price {
    color: #939393;
    letter-spacing: 0;
  }
}

.optionsContainer {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 32px;
  }
}

.submitButtonContainer {
  padding: 16px 24px 24px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
}
