.swiperWrapper {
  width: 100%;
  height: 166px;
  margin-top: -20px;
  margin-bottom: -20px;
  overflow: hidden;
  position: relative;
  cursor: grab;

  &WithImage {
    height: 202px;
  }
}

.swiper {
  padding: 0 24px;
  overflow: scroll;
  overflow-y: hidden;
  position: absolute;
  top: -20px;
  left: 0;
  bottom: -20px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.swiperSlide {
  flex: 1;
  max-width: 134px;
  max-height: 126px;

  &WithImage {
    max-height: 162px;
  }
}
