@import 'utils/scss/extensions.scss';

.notFoundDishError {
  margin-top: 25px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  :global {
    .adm-error-block-content {
      margin-top: 24px;
    }

    .adm-error-block-description-title {
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
    }

    .adm-error-block-description-subtitle {
      margin-top: 4px;
      text-align: left;
      font-size: 14px;
      line-height: 22px;
    }

    .adm-error-block-image {
      height: max-content;
      width: max-content;

      svg {
        width: auto;
        height: auto;
      }
    }

    @media screen and (min-width: $desktopBreakpoint) {
      .adm-error-block-description-title,
      .adm-error-block-description-subtitle {
        text-align: center;
      }
    }
  }
}

.cancelButton {
  @extend %textButtonShared;
  font-size: 17px;
  line-height: 140%;
}
