.item {
  min-width: 134px;
  max-width: 134px;
  min-height: 162px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.imageContainer {
  width: 100%;
  margin-bottom: 4px;
  padding-top: 61%;
  position: relative;
  border-radius: 4px;
}

.image {
  border-radius: 4px;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.title {
  width: 100%;
  max-height: 22px;
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weight {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #a5a5a5;
}

.price {
  display: block;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  line-height: 22px;
}

.bottomBlock {
  display: flex;
  justify-content: space-between;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  align-self: flex-end;

  margin-bottom: 4px;

  svg {
    width: 100%;
    height: 100%;

    color: #333333;
  }
}
