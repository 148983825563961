@import '/src/utils/scss/variables.scss';

.option {
  &Item {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 8px;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background: linear-gradient(90deg, #fe961d 0%, #ff4018 100%);
      border-radius: 100px;
    }
  }

  &Name {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  &Price {
    font-size: 14px;
    line-height: 22px;
    color: #a1a1a1;
  }
}

.optionList {
  margin: 0;
  padding: 0;
  list-style: none;

  &Title {
    margin: 0 0 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}

.optionsContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.radioItem {
  --icon-size: 16px;

  :global {
    .adm-radio-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .adm-radio-custom-icon {
      width: 16px;
      height: 16px;
      align-self: center;
      justify-self: center;
    }
  }
}
