.image {
  width: 100%;
  height: 100%;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.skeletonWrapper {
  pointer-events: none;
  background-color: #fff;
  opacity: 0;

  &Active {
    opacity: 1;
  }
}
