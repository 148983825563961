.container {
  :global {
    .adm-capsule-tabs-header {
      padding: 12px 16px;
    }

    .adm-capsule-tabs-tab-wrapper {
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .adm-capsule-tabs-tab {
      height: 38px;
      padding: 0;
    }
  }
}

.tabs {
  margin: 0 -16px 16px;
}

.title {
  min-width: 163px;
  min-height: 40px;
  border-radius: 8px;
}
