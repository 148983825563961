.container {
  width: 100%;
  max-width: 800px;
  min-height: 40%;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.swiperWrapper {
  --border-radius: 8px;
  --track-padding: 0 0 7px;

  :global {
    .adm-image-tip {
      min-height: calc((100vw + 55px) / 3);
    }

    .adm-swiper-indicator {
      bottom: 0;
    }

    .adm-swiper-slide {
      border-radius: 8px;
      overflow: hidden;
    }

    .adm-page-indicator-dot-active {
      background: #fd1c15;
    }
  }
}

.itemContainer {
  position: relative;
  width: 100%;
  max-width: 768px;
  //height: calc(calc(100vw + 55px) / 3);
  height: calc(calc(calc(100vw - 32px) / 100) * 43.4375);
  max-height: 334px;
}

.promoName {
  margin: 12px auto 0 auto;
  width: fit-content;
}
