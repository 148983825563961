.promoPageContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  min-height: calc(100vh - 59px);
}

.promoNavContainer {
  width: 100%;
  height: 60px;
  position: relative;
}

.promoInfoContainer {
  margin-top: 8px;
  padding: 0 24px 24px;
}

.promoTitle {
  margin: 0 0 4px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.promoDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.promoSectionTitle {
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.backdrop {
  &-1 {
    background: linear-gradient(270deg, #faff1d 0%, #ffa800 100%);
  }

  &-2 {
    background: linear-gradient(270deg, #4cfe0d 0%, #e1ff6a 100%);
  }

  &-3 {
    background: linear-gradient(90deg, #ffe5bd 0%, #ffbdbd 103.08%);
  }

  &-4 {
    background: linear-gradient(270deg, #bdf7ff 0%, #f8cbff 100%);
  }

  &-5 {
    background: linear-gradient(90deg, #ffffff 0.76%, #00b8fd 18.74%, #00b8fd 78.84%, #ffffff 99.39%);
  }

  &-6 {
    background: #ffffff;
  }
}
