.container {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
}

.link {
  text-decoration: none;
  font-weight: 500;
  background: linear-gradient(90deg, #fe961d 0%, #ff4018 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}
