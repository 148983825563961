.container {
  padding: 16px 0;
  display: grid;
  grid-template-columns: 0.64fr 1fr;
  gap: 0 8px;
  border-bottom: 1px solid #f2f2ef;

  @media screen and (min-width: 570px) {
    grid-template-columns: 200px 1fr;
  }
}

.itemDisabled {
  background: #f2f2ef;
  margin: 0 -24px;
  padding: 16px 24px;
}

.imageContainer {
  max-width: 200px;
  position: relative;
  object-fit: cover;
  aspect-ratio: 1 / 1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.title {
  margin: 0 0 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.price {
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.weight {
  font-size: 14px;
  line-height: 22px;
  color: #a5a5a5;
}

.size {
  font-size: 14px;
  line-height: 22px;
}

.modifierList {
  margin: 0 0 8px;
  padding: 0;
}

.modifierItem {
  list-style-type: none;
  font-size: 14px;
  line-height: 22px;

  &::before {
    content: '+';
    margin-right: 4px;
  }
}

.counter {
  box-sizing: border-box;
  min-width: 120px;
  margin-right: 14px;
  --height: 47px;
  --button-width: 40px;
  --button-background-color: #fff;
  --button-text-color: #333333;
  --button-font-size: 14px;
  --border: 1px solid #eee;
  --border-inner: 1px solid #eee;
  --border-radius: 4px;
  --input-width: 40px;
  --input-background-color: #fff;
  --input-font-color: #333333;
  --input-font-size: 14px;
}
