.swiperWrapper {
  width: 100%;
  height: 202px;
  margin-top: -20px;
  margin-bottom: -20px;
  overflow: hidden;
  position: relative;

  :global {
    .adm-skeleton {
      margin: 0;
    }
  }
}

.swiper {
  padding: 0 24px;
  overflow: scroll;
  position: absolute;
  top: -20px;
  left: 0;
  bottom: -20px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.swiperSlide {
  flex: 1;
  max-width: 134px;
  max-height: 162px;
}

.item {
  width: 100%;
  min-width: 134px;
  min-height: 162px;
  box-sizing: border-box;
  border-radius: 8px;
}
