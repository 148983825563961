@import '/src/utils/scss/extensions.scss';

.container {
  :global {
    .adm-button-disabled {
      width: 100%;
      background: #fff;
      border: 1px solid #eee;
      color: #333333;
      opacity: 1;
    }
  }
}

.counterContainer {
  display: flex;
  max-height: 49px;
}

.counter {
  box-sizing: border-box;
  min-width: 120px;
  margin-right: 14px;
  --height: 47px;
  --button-width: 40px;
  --button-background-color: #fff;
  --button-text-color: #333333;
  --button-font-size: 14px;
  --border: 1px solid #eee;
  --border-inner: 1px solid #eee;
  --border-radius: 4px;
  --input-width: 40px;
  --input-background-color: #fff;
  --input-font-color: #333333;
  --input-font-size: 14px;
}

.button {
  @extend %submitButtonShared;
  font-size: 18px;
  line-height: 25px;
}
