.container {
  padding-bottom: 24px;
}

.navbar {
  width: 100%;
  height: 60px;
}

.info {
  margin-top: 8px;
  padding: 0 24px;

  :global {
    .adm-skeleton {
      margin: 0 0 4px;
      border-radius: 8px;
    }

    .adm-skeleton-paragraph-line {
      height: 22px;
    }
  }
}

.infoTitle {
  width: 60%;
  height: 40px;
  margin-bottom: 12px;
}

.paragraph2 {
  margin-bottom: 28px;
  :global {
    .adm-skeleton.adm-skeleton-paragraph-line {
      width: 87%;

      &:last-child {
        width: 94%;
      }

      &:first-child {
        width: 100%;
      }
    }
  }
}

.listTitle {
  width: 172px;
  height: 28px;
  margin-bottom: 4px;
}
