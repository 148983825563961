.container {
  width: 100%;

  :global {
    .adm-nav-bar {
      padding: 0;
      font-weight: 500;
      margin-bottom: 16px;
      height: 50px;
      display: grid;
      grid-template-columns: 1fr minmax(100px, 300px) 1fr;
    }

    .adm-nav-bar-back {
      min-width: 45px;
    }

    .adm-nav-bar-left {
      min-width: 45px;
    }

    .adm-nav-bar-right {
      min-width: 45px;
    }
  }
}

.main {
  :global {
    .adm-nav-bar-title {
      font-size: 24px;
    }

    .adm-nav-bar-back {
      min-width: 0;
      margin: 0;
      padding: 0;
    }

    .adm-nav-bar-left {
      min-width: 0;
    }

    .adm-nav-bar-right {
      min-width: 0;
    }
  }
}
