.container {
  padding-bottom: 24px;
}

.imageContainer {
  width: 100%;
  padding-top: 61%;
  position: relative;
  margin-bottom: 24px;

  :global {
    .adm-skeleton {
      width: 100%;
      height: 100%;
    }
  }
}

.image {
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.info {
  padding: 0 24px;

  :global {
    .adm-skeleton {
      margin: 0 0 4px;
      border-radius: 8px;
    }

    .adm-divider {
      margin: 12px 0 8px;
    }

    .adm-skeleton-paragraph-line {
      height: 22px;
    }
  }
}

.infoTitle {
  width: 60%;
  height: 40px;
}

.flexContainer {
  max-width: 114px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.flexContainerItem {
  width: 53px;
  height: 28px;
}

.paragraph1 {
  width: 220px;
}

.paragraph2 {
  margin-bottom: 20px;
  :global {
    .adm-skeleton.adm-skeleton-paragraph-line {
      width: 87%;

      &:last-child {
        width: 94%;
      }

      &:first-child {
        width: 100%;
      }
    }
  }
}

.paragraph3 {
  margin-bottom: 8px;

  :global {
    .adm-skeleton.adm-skeleton-paragraph-line {
      width: 228px;
      height: 28px;

      &:last-child {
        width: 90px;
      }
    }
  }
}
