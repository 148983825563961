.container {
  will-change: auto;
  background-color: #fff;
  margin: 0 -16px 16px -16px;
  position: relative;
  height: 62px;

  :global {
    .adm-capsule-tabs-header {
      padding: 12px 0 12px 0;
    }

    .adm-capsule-tabs-tab {
      border-radius: 24px;
      font-size: 14px;
      line-height: 22px;

      &-wrapper {
        flex: 0;
      }

      &-list {
        padding: 0 10px;
      }

      &-active {
        background: linear-gradient(90deg, #fe961d 0%, #ff4018 100%);
      }
    }
  }
}

.stickyContainer {
  will-change: auto;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: -1px;
  z-index: 100;

  @media screen and (min-width: 800px) {
    left: calc((100vw - 800px) / 2);
    right: calc((100vw - 800px) / 2);
  }
}

.relative {
  position: relative;
}
