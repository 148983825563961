@import 'utils/scss/extensions.scss';

.popup {
  padding: 32px 24px 24px;
  border-radius: 16px;

  :global {
    .adm-action-sheet-extra {
      max-width: 400px;
      margin: 0 auto 32px;
      padding: 0;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #333333;
      border: none;
    }

    .adm-action-sheet-button-list {
      border: none;
    }

    .adm-action-sheet-button-item-wrapper {
      border: none;
    }

    .adm-action-sheet-button-item {
      margin: 0 auto;
      max-width: 400px;
      padding: 12px;
      border-radius: 4px;
      font-size: 18px;
      line-height: 25px;
    }

    .adm-action-sheet-button-item-danger {
      margin-bottom: 8px;
      background-color: #ff3141;

      .adm-action-sheet-button-item-name {
        color: #fff;
      }
    }
  }
}

.popupContainer {
  @extend %popupContainerShared;
}
