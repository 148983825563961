.floatingPanel {
  --border-radius: 16px;

  max-width: 800px;

  @media screen and (min-width: 800px) {
    left: calc((100vw - 800px) / 2);
    right: calc((100vw - 800px) / 2);

    ::after {
      background-color: transparent;
    }
  }

  :global {
    .adm-floating-panel-header {
      display: none;
    }

    .adm-floating-panel-content {
      --border-radius: 16px;
      border-radius: 16px 16px 0 0;
      overflow-y: auto;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;

  :global {
    .adm-divider {
      margin-bottom: 8px;
    }
  }
}

.infoContainer {
  margin-top: 24px;
  padding: 0 24px;

  &WithImage {
    margin-top: 16px;
  }
}

.title {
  max-height: 64px;
  margin: 0 0 4px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.header {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.ingredients {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 22px;
  color: #a2a2a2;
}

.weight {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #9d9d9d;
}

.price {
  margin-right: 8px;
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.description {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 22px;
}

.imageContainer {
  width: 100%;
  padding-top: 61%;
  max-height: 488px;
  position: relative;

  .image {
    align-self: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: #fff url(../../images/close-icon.svg) center no-repeat;
}

.sectionTitle {
  padding: 0 24px;
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.mainInfoContainer {
  min-height: 154px;
  padding: 8px 24px 16px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  background: #fff;
  border-top: 1px solid #f2f2ef;
}

.backgroundOverlay {
  position: fixed;
  top: -400px;
  left: -400px;
  right: -400px;
  bottom: -800px;
  background-color: #00000099;
  pointer-events: none;
  z-index: 100;

  &Active {
    pointer-events: all;
  }
}

.recommendedDishesContainer {
  margin: 0 -16px 32px -24px;
}
