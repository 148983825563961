@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}
