.link {
  text-decoration: none;
  border-radius: 8px;
}

.container {
  min-height: 126px;

  &WithDescription {
    min-height: 170px;
  }
}

.item {
  position: relative;
  padding: 8px 8px 12px 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  z-index: auto;

  &::before {
    content: '';
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &Disabled::before {
    box-shadow: none;
  }
}

.imageContainer {
  width: 100%;
  padding-top: 61%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.infoContainer {
  margin-top: 8px;
  min-height: 98px;
  padding: 0 8px 2px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &WithDescription {
    min-height: 142px;
  }
}

.title {
  margin: 0;
  max-height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.weight {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #a5a5a5;
}

.description {
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a1a1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.price {
  display: block;
  font-weight: 500;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}

.disabled {
  pointer-events: none;

  .item {
    background: rgb(237, 237, 237);
    pointer-events: none;
  }

  .title {
    color: #a5a5a5;
  }
}

.bottomBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  position: absolute;
  bottom: -2px;
  right: -4px;
}
